import { MODAL_OFFSET } from '../filter/filter.const';
import { ControlledFilterSelectProps } from '../FilterSelect/ControlledFilterSelect';

export const POPOVER_MENU_PROPS: Partial<ControlledFilterSelectProps['MenuProps']> = {
  anchorOrigin: {
    horizontal: 'left',
    vertical: 'bottom',
  },
  transformOrigin: {
    horizontal: -MODAL_OFFSET.horizontal,
    vertical: -MODAL_OFFSET.vertical,
  },
};