export const isErrorLike = (e: unknown): e is Error | { message: string; } => {
  return e instanceof Error || (typeof e === 'object' && e !== null && 'message' in e);
};

export const stringifyError = (e: unknown): string => {
  if (e instanceof Error) {
    const error: Record<string, unknown> = {};
    Object.getOwnPropertyNames(e).forEach(function (propName) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      error[propName] = (e as any)[propName];
    });
    return JSON.stringify(error);
  }
  return JSON.stringify(e);
};
