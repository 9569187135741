import { FC } from 'react';

const MobileLogo: FC = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 91 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.32229 40.4998H5.17654V36.3074H1.5256V40.4998H0.38623V31.4302H1.5256V35.3299H5.17654V31.4302H6.32229V40.4998Z" fill="black" />
      <path d="M12.965 36.3075H9.75736V39.5215H13.4944V40.4998H8.61719V31.4303H13.4322V32.4078H9.75736V35.3292H12.965V36.3075Z" fill="black" />
      <path d="M16.4172 35.8528H17.7998C18.2774 35.8528 18.6593 35.6989 18.9432 35.3912C19.227 35.0834 19.3697 34.6712 19.3697 34.1553C19.3697 32.9904 18.8427 32.4084 17.7878 32.4084H16.4172V35.8528ZM18.0119 36.8311H16.4172V40.4996H15.2715V31.4301H17.8126C18.7008 31.4301 19.3737 31.6621 19.8306 32.1245C20.2874 32.5878 20.5163 33.2655 20.5163 34.1585C20.5163 34.719 20.3919 35.2078 20.1455 35.6256C19.8984 36.0426 19.5483 36.3575 19.0954 36.5688L20.852 40.4247V40.4996H19.6249L18.0119 36.8311Z" fill="black" />
      <path d="M23.5005 32.4081V39.5218H24.4158C25.2172 39.5218 25.8032 39.2922 26.1731 38.833C26.5423 38.3737 26.7313 37.6928 26.7392 36.791V35.182C26.7392 34.2268 26.5559 33.5252 26.1883 33.0787C25.8207 32.6314 25.2658 32.4081 24.5219 32.4081H23.5005ZM22.3604 40.4994V31.4298H24.5219C25.5807 31.4298 26.4036 31.7615 26.9888 32.4241C27.574 33.0867 27.8675 34.0179 27.8675 35.2171V36.7376C27.8675 37.9376 27.5701 38.8649 26.976 39.5187C26.382 40.1725 25.5209 40.4994 24.3911 40.4994H22.3604Z" fill="black" />
      <path d="M31.1062 39.5218H34.6631V40.5001H29.9604V31.4305H31.1062V39.5218Z" fill="black" />
      <path d="M36.3911 31.4299H37.5312V40.4995H36.3911V31.4299Z" fill="black" />
      <path d="M45.7727 40.4997H44.627L40.9952 33.5104V40.4997H39.8486V31.4301H40.9952L44.6389 38.4506V31.4301H45.7727V40.4997Z" fill="black" />
      <path d="M52.4132 36.3075H49.2056V39.5215H52.9426V40.4998H48.0654V31.4303H52.8805V32.4078H49.2056V35.3292H52.4132V36.3075Z" fill="black" />
      <path d="M3.02377 18.7156H8.20079C9.16475 18.7156 9.86639 18.5833 10.3033 18.3185C10.8176 18.0283 11.0767 17.5451 11.0767 16.8714C11.0767 16.1969 10.8176 15.7081 10.3033 15.4035C9.86639 15.1532 9.16475 15.0272 8.20079 15.0272H3.02377V18.7156ZM3.02377 12.7859H8.37939C9.19824 12.7859 9.78028 12.6735 10.1239 12.4487C10.5074 12.1839 10.6996 11.7741 10.6996 11.2184C10.6996 10.6507 10.5178 10.2441 10.1542 9.99929C9.79065 9.75531 9.19824 9.63252 8.37939 9.63252H3.02377V12.7859ZM11.2354 13.8567C12.9273 14.426 13.7733 15.4904 13.7733 17.05C13.7733 18.2795 13.3499 19.245 12.5047 19.9459C12.0686 20.3023 11.5655 20.5574 10.9978 20.7089C10.4285 20.8612 9.71411 20.937 8.85539 20.937H0.38623V7.41118H8.75652C9.628 7.41118 10.3233 7.47736 10.8383 7.60891C11.3542 7.74207 11.8031 7.96611 12.1866 8.28345C12.9536 8.90536 13.3371 9.81032 13.3371 11.0007C13.3371 12.3235 12.6363 13.2747 11.2354 13.8567Z" fill="black" />
      <path d="M20.9105 13.1819H25.6306C26.3179 13.1819 26.8043 13.162 27.0889 13.1229C27.3728 13.0831 27.6463 13.0041 27.9118 12.8853C28.4675 12.6071 28.7442 12.1183 28.7442 11.4175C28.7442 10.7031 28.4675 10.2151 27.9118 9.94959C27.6606 9.81803 27.3935 9.73192 27.1089 9.69205C26.8242 9.65219 26.3315 9.63225 25.6306 9.63225H20.9105V13.1819ZM20.9105 15.424V20.9367H18.2729V7.41091H25.9488C26.9662 7.41091 27.773 7.47071 28.3679 7.58951C28.9635 7.70831 29.4777 7.91402 29.9147 8.20425C30.4042 8.54789 30.7742 9.01752 31.0253 9.61232C31.2494 10.1808 31.3626 10.79 31.3626 11.4366C31.3626 13.646 30.1004 14.9352 27.5745 15.3044L31.4423 20.9367H28.5664L24.7576 15.424H20.9105Z" fill="black" />
      <path d="M42.8821 9.4539C41.5474 9.4539 40.436 9.83741 39.5509 10.6044C38.4921 11.5166 37.9635 12.7062 37.9635 14.174C37.9635 15.6419 38.4921 16.8259 39.5509 17.7245C40.436 18.4916 41.5546 18.8743 42.9029 18.8743C44.2376 18.8743 45.3483 18.4916 46.2349 17.7245C46.7627 17.2748 47.1662 16.7199 47.4436 16.0581C47.694 15.4633 47.8208 14.855 47.8208 14.2338C47.8208 12.6998 47.2913 11.4903 46.2349 10.6044C45.3347 9.85097 44.2185 9.46745 42.8821 9.4539ZM42.8622 7.17276C45.1904 7.17276 47.0274 7.80743 48.3765 9.07756C49.8045 10.4123 50.5173 12.1377 50.5173 14.2538C50.5173 15.5494 50.1744 16.759 49.4864 17.8824C48.15 20.0774 45.9502 21.1753 42.8821 21.1753C40.5819 21.1753 38.7576 20.5399 37.4077 19.2713C36.6941 18.584 36.1448 17.7636 35.762 16.8116C35.4312 15.9792 35.2661 15.0997 35.2661 14.174C35.2661 12.8122 35.6098 11.5692 36.2971 10.4458C37.5799 8.30336 39.7678 7.21263 42.8622 7.17276Z" fill="black" />
      <path d="M57.7517 18.7156H67.1138V20.937H55.1157V7.41121H57.7517V18.7156Z" fill="black" />
      <path d="M73.6361 7.41131H70.9985V20.9371H73.6361V7.41131Z" fill="black" />
      <path d="M90.4298 9.19609L89.2601 11.3377C87.9772 10.1744 86.3435 9.54611 84.3605 9.45362C83.6469 9.45362 83.0115 9.53973 82.4573 9.71116C82.0332 9.84431 81.6935 10.042 81.436 10.3068C81.1784 10.5715 81.0485 10.8617 81.0485 11.179C81.0485 11.6813 81.3921 12.025 82.0802 12.21C82.3983 12.3033 83.0848 12.4021 84.1429 12.5082L85.7104 12.6668C86.6225 12.7721 87.3593 12.9116 87.9214 13.083C88.4835 13.2553 88.9746 13.4929 89.3988 13.7966C89.835 14.1275 90.1762 14.5573 90.4202 15.0867C90.665 15.6153 90.7878 16.1774 90.7878 16.7722C90.7878 17.7242 90.4967 18.5702 89.9147 19.3109C89.3717 19.9847 88.5329 20.4878 87.3959 20.8179C86.5356 21.0555 85.6163 21.1751 84.6388 21.1751C82.8679 21.1751 80.7837 20.5404 78.3926 19.271L79.2832 16.9708C79.7999 17.4069 80.6259 17.8327 81.7629 18.2497C82.8998 18.6659 83.8774 18.874 84.697 18.874C85.6107 18.874 86.3841 18.7289 87.0188 18.4378C87.3888 18.2664 87.6862 18.0448 87.911 17.7737C88.1367 17.5026 88.2483 17.2156 88.2483 16.911C88.2483 16.4621 87.9708 16.1448 87.4151 15.9598C87.0706 15.8274 86.3778 15.7014 85.3317 15.5826L83.8056 15.424C82.7866 15.3179 82.0746 15.2191 81.6632 15.1266C81.2534 15.0341 80.8435 14.8818 80.4345 14.6697C79.8533 14.3659 79.3956 13.9162 79.0655 13.3214C78.7753 12.792 78.6294 12.1773 78.6294 11.4764C78.6294 10.3394 79.0057 9.39462 79.76 8.64036C80.7503 7.66204 82.2588 7.17249 84.2816 7.17249C85.1411 7.17249 86.0588 7.29846 87.0379 7.54962C88.0171 7.80078 89.1485 8.35013 90.4298 9.19609Z" fill="black" />
      <path d="M90.79 0.500092H0.384766V3.1384H90.79V0.500092Z" fill="black" />
      <path d="M90.79 24.9834H0.384766V27.6218H90.79V24.9834Z" fill="black" />
    </svg>
  );
};

export default MobileLogo;