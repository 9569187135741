import { FC } from 'react';

const Logo: FC = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 199 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M141.78 22.5372H140.381V17.418H135.923V22.5372H134.531V11.4624H135.923V16.2243H140.381V11.4624H141.78V22.5372Z" />
      <path d="M149.891 17.4185H145.974V21.3431H150.538V22.5377H144.582V11.4629H150.462V12.6565H145.974V16.2239H149.891V17.4185Z" />
      <path d="M154.106 16.863H155.794C156.378 16.863 156.844 16.6751 157.191 16.2993C157.537 15.9235 157.711 15.4201 157.711 14.7902C157.711 13.3678 157.068 12.657 155.78 12.657H154.106V16.863ZM156.053 18.0577H154.106V22.5372H152.707V11.4624H155.81C156.895 11.4624 157.716 11.7457 158.274 12.3104C158.832 12.8761 159.111 13.7037 159.111 14.7941C159.111 15.4786 158.96 16.0754 158.659 16.5856C158.357 17.0947 157.929 17.4793 157.376 17.7373L159.521 22.4457V22.5372H158.023L156.053 18.0577Z" />
      <path d="M162.756 12.6565V21.3431H163.873C164.852 21.3431 165.567 21.0627 166.019 20.5019C166.47 19.9411 166.701 19.1096 166.71 18.0085V16.0437C166.71 14.8773 166.486 14.0206 166.038 13.4753C165.589 12.9291 164.911 12.6565 164.003 12.6565H162.756ZM161.363 22.5368V11.4619H164.003C165.296 11.4619 166.3 11.8669 167.015 12.676C167.73 13.4851 168.088 14.6223 168.088 16.0866V17.9433C168.088 19.4085 167.725 20.5408 167 21.3392C166.274 22.1376 165.223 22.5368 163.843 22.5368H161.363Z" />
      <path d="M172.044 21.3431H176.387V22.5377H170.645V11.4629H172.044V21.3431Z" />
      <path d="M178.496 11.4624H179.889V22.5373H178.496V11.4624Z" />
      <path d="M189.953 22.5372H188.554L184.119 14.0026V22.5372H182.719V11.4624H184.119L188.568 20.0351V11.4624H189.953V22.5372Z" />
      <path d="M198.061 17.4185H194.144V21.3431H198.708V22.5377H192.752V11.4629H198.632V12.6565H194.144V16.2239H198.061V17.4185Z" />
      <path d="M3.22266 22.6838H9.54433C10.7214 22.6838 11.5782 22.5221 12.1117 22.1989C12.7397 21.8445 13.0561 21.2545 13.0561 20.4318C13.0561 19.6081 12.7397 19.0113 12.1117 18.6394C11.5782 18.3337 10.7214 18.1798 9.54433 18.1798H3.22266V22.6838ZM3.22266 15.443H9.76242C10.7623 15.443 11.4731 15.3057 11.8927 15.0312C12.361 14.7079 12.5956 14.2075 12.5956 13.5289C12.5956 12.8357 12.3736 12.3391 11.9297 12.0402C11.4857 11.7423 10.7623 11.5924 9.76242 11.5924H3.22266V15.443ZM13.2499 16.7506C15.3159 17.4457 16.3489 18.7455 16.3489 20.6499C16.3489 22.1512 15.8319 23.3302 14.7999 24.186C14.2673 24.6212 13.653 24.9328 12.9598 25.1178C12.2646 25.3037 11.3922 25.3962 10.3437 25.3962H0.00195312V8.87988H10.2229C11.2871 8.87988 12.1361 8.96069 12.765 9.12134C13.395 9.28393 13.9431 9.55752 14.4114 9.94501C15.348 10.7044 15.8163 11.8095 15.8163 13.2631C15.8163 14.8783 14.9605 16.0398 13.2499 16.7506Z" />
      <path d="M25.0645 15.9269H30.8282C31.6675 15.9269 32.2614 15.9025 32.609 15.8548C32.9556 15.8062 33.2895 15.7098 33.6138 15.5647C34.2924 15.2249 34.6302 14.6281 34.6302 13.7723C34.6302 12.8999 34.2924 12.3041 33.6138 11.9799C33.3071 11.8192 32.9809 11.7141 32.6333 11.6654C32.2857 11.6167 31.6841 11.5924 30.8282 11.5924H25.0645V15.9269ZM25.0645 18.6647V25.3962H21.8438V8.87988H31.2167C32.459 8.87988 33.4443 8.9529 34.1707 9.09797C34.8979 9.24304 35.5259 9.49423 36.0595 9.84863C36.6573 10.2683 37.109 10.8417 37.4157 11.568C37.6893 12.2622 37.8275 13.0061 37.8275 13.7957C37.8275 16.4935 36.2863 18.0679 33.2019 18.5186L37.9249 25.3962H34.4131L29.7621 18.6647H25.0645Z" />
      <path d="M51.8937 11.3744C50.2639 11.3744 48.9067 11.8427 47.8259 12.7793C46.533 13.8931 45.8875 15.3457 45.8875 17.1382C45.8875 18.9306 46.533 20.3764 47.8259 21.4737C48.9067 22.4103 50.2726 22.8776 51.919 22.8776C53.5488 22.8776 54.9051 22.4103 55.9877 21.4737C56.6323 20.9245 57.1249 20.2469 57.4637 19.4388C57.7694 18.7125 57.9242 17.9696 57.9242 17.2112C57.9242 15.3379 57.2778 13.861 55.9877 12.7793C54.8885 11.8592 53.5255 11.3909 51.8937 11.3744ZM51.8694 8.58887C54.7123 8.58887 56.9555 9.36386 58.6029 10.9148C60.3466 12.5447 61.217 14.6516 61.217 17.2355C61.217 18.8176 60.7984 20.2946 59.9581 21.6664C58.3264 24.3468 55.6402 25.6874 51.8937 25.6874C49.0848 25.6874 46.8572 24.9115 45.2089 23.3625C44.3375 22.5232 43.6667 21.5214 43.1993 20.3589C42.7953 19.3424 42.5938 18.2685 42.5938 17.1382C42.5938 15.4752 43.0134 13.9574 43.8526 12.5855C45.4192 9.96945 48.0908 8.63755 51.8694 8.58887Z" />
      <path d="M70.0508 22.6838H81.4829V25.3962H66.832V8.87988H70.0508V22.6838Z" />
      <path d="M89.4473 8.88037H86.2266V25.3967H89.4473V8.88037Z" />
      <path d="M109.954 11.0594L108.526 13.6745C106.96 12.254 104.965 11.4868 102.543 11.3739C101.672 11.3739 100.896 11.479 100.219 11.6884C99.7014 11.851 99.2866 12.0924 98.9721 12.4156C98.6577 12.7389 98.499 13.0933 98.499 13.4808C98.499 14.0942 98.9186 14.5138 99.7588 14.7397C100.147 14.8536 100.986 14.9743 102.278 15.1038L104.192 15.2975C105.305 15.4261 106.205 15.5964 106.891 15.8058C107.578 16.0161 108.178 16.3062 108.696 16.6771C109.228 17.0812 109.645 17.606 109.943 18.2524C110.242 18.898 110.392 19.5843 110.392 20.3107C110.392 21.4732 110.036 22.5062 109.326 23.4106C108.662 24.2333 107.638 24.8477 106.25 25.2508C105.199 25.5409 104.077 25.6869 102.883 25.6869C100.721 25.6869 98.1757 24.912 95.2559 23.362L96.3434 20.5531C96.9743 21.0857 97.9829 21.6056 99.3713 22.1148C100.76 22.623 101.953 22.8771 102.954 22.8771C104.07 22.8771 105.014 22.6999 105.789 22.3445C106.241 22.1352 106.604 21.8645 106.879 21.5335C107.154 21.2025 107.291 20.852 107.291 20.4801C107.291 19.9319 106.952 19.5444 106.273 19.3186C105.853 19.1569 105.007 19.0031 103.729 18.858L101.866 18.6643C100.621 18.5348 99.752 18.4141 99.2496 18.3011C98.7492 18.1882 98.2487 18.0022 97.7493 17.7432C97.0395 17.3723 96.4807 16.8232 96.0776 16.0969C95.7232 15.4504 95.545 14.6997 95.545 13.8439C95.545 12.4556 96.0046 11.3018 96.9256 10.3808C98.1348 9.18618 99.9769 8.58838 102.447 8.58838C103.497 8.58838 104.617 8.74221 105.813 9.0489C107.008 9.35559 108.39 10.0264 109.954 11.0594Z" />
      <path d="M110.394 0.440918H0V3.66256H110.394V0.440918Z" />
      <path d="M110.394 30.3374H0V33.5591H110.394V30.3374Z" />
      <path d="M123 0.440918H124V33.4409H123V0.440918Z" />
    </svg>
  );
};

export default Logo;
